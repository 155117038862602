import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { TLanguage, TTask } from "hooks/data"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

type TQueryParams = operations["Tasks_Post"]["parameters"]["query"]

type TData = TTask
type TError = unknown
type TPayload = Pick<TTask, "IsDeactivated"> &{
  BezeichnungML: TLanguage
  CatAbteilung: { ID: number } | null
  CatFunktion: { ID: number }
}
type THook = (
  options?: UseMutationOptions<TData, TError, TPayload>
) => UseMutationResult<TData, TError, TPayload>

const createTask = (payload: TPayload) =>
  fetchJson<TData, false, TQueryParams>(`tasks`, {
    method: "POST",
    body: payload,
    queryParams: {
      renderSpracheId: "de",
    },
  })

export const useCreateTask: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation({
    mutationFn: createTask,
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
