import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useCurrentUserContext } from "../../../../context/CurrentUserContext"
import { useDepartments } from "../../../../hooks/data"
import { OrganisationUnit } from "../../../../types"
import ComboboxGeneric from "../../../forms/ComboboxGeneric"

interface Props {
  orgUnits: OrganisationUnit[]
  onUpdate: (units: OrganisationUnit[]) => void
  disabled?: boolean
}

const UserSelectOrgUnit: FC<Props> = ({ orgUnits, onUpdate, disabled }) => {
  const { t } = useTranslation("departmentsPage")
  const { UILanguage } = useCurrentUserContext()

  const { data: departments } = useDepartments()

  const options =
    departments?.map(({ BezeichnungML, CatAbteilungID }) => ({
      value: CatAbteilungID,
      primary: BezeichnungML[UILanguage],
      label: BezeichnungML.Code
    })) ?? []

  const selectedOrgUnits = useMemo(
    () =>
      departments
        ?.map(({ BezeichnungML, CatAbteilungID }) => ({
          value: CatAbteilungID,
          primary: BezeichnungML[UILanguage],
          label: BezeichnungML.Code
        }))
        .filter(({ value }) => orgUnits.map(({ ID }) => ID).includes(value)) ??
      [],
    [UILanguage, departments, orgUnits]
  )

  return (
    <div>
      <ComboboxGeneric
        disabled={disabled}
        label={t("title")}
        options={options}
        value={selectedOrgUnits}
        onChange={(values) =>
          onUpdate(values.map(({ value }) => ({ ID: value })))
        }
      />
      <div className="mt-1 text-right text-xs text-gray-600">
        When none are selected, user has access to all Organisational Units
      </div>
    </div>
  )
}

export default UserSelectOrgUnit
