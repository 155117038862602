import { AxiosResponse } from "axios"
import { useMemo } from "react"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"

import { EGenders, ELanguages } from "src/constants"
import { TLanguageCodes, TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { functionKeys } from "./keys"
import { DEFAULT_REQUEST_FUNCTION_PARAMS } from "./useFunctionMutation"

function sortFunctionSelectItemsAsc(
  a: { primary: string | null },
  b: { primary: string | null }
): number {
  if (a.primary != null && b.primary != null) {
    // Use Intl.collator for better performance
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#performance
    return a.primary.localeCompare(b.primary)
  }
  if (a.primary == null && b.primary != null) {
    return 1
  }
  if (a.primary != null && b.primary == null) {
    return -1
  }

  return 0
}

// WORKAROUND:
// This has to be done on the backend, ideally.
export const useFuntionSelectItemsSortedAsc = ({
  language,
  data,
  gender,
}: {
  language: ELanguages
  data?: IFunction[]
  gender?: 1 | 2 | EGenders
}): { value: number; primary: string | null; label: string }[] =>
  useMemo(
    () =>
      data && gender
        ? data
          .map((position) => {
            const label = position.BezeichnungMaennlichML.Code ?? "";

            return {
              value: position.CatFunktionID,
              primary: position[
                  String(gender) === EGenders.FEMALE
                    ? "BezeichnungWeiblichML"
                    : "BezeichnungMaennlichML"
                ][language],
              label: label.replace(/^Job_Title_F_|^Job_Title_M_/, ""),
            }
          })
            .sort(sortFunctionSelectItemsAsc)
        : [],
    [data, gender, language]
  )

export interface IFunction {
  CatFunktionID: number
  Bezeichnung: string
  BezeichnungMaennlichML: TLanguage
  BezeichnungWeiblichML: TLanguage
  InitSettings: {
    AufgabeTemplateIDs: number[]
    Evaluations: Array<{
      BeurteilungsOptionenIDs: number[]
      CatBeurteilungLevelID: number
      RenderZeugnisTypId: number
    }>
  }
  IsDeactivated: boolean
  IsInUse: boolean
}

// QUESTION:
// Should `renderSpracheId` be required?
export type TUseFunctionsQueryParams =
  operations["CatFunktionen_GetList"]["parameters"]["query"] & TODataParams

export type TLanguage = Record<TLanguageCodes, string | null> & {
  Code: string | null
}

type TData = IFunction[]

const defaultParams = {
  queryOnlyShortInfo: true,
  ...DEFAULT_REQUEST_FUNCTION_PARAMS,
}

const getFunctions = <TFullResponse>(params: TUseFunctionsQueryParams) =>
  fetchJson<TData, TFullResponse, TUseFunctionsQueryParams>(`catfunktionen`, {
    queryParams: params,
    fullResponse: params.$inlinecount === "allpages",
  })

export const useFunctions = <TFullResponse>(
  params?: Partial<TUseFunctionsQueryParams>,
  options?: UseQueryOptions<
    TFullResponse extends true ? AxiosResponse<TData> : TData
  >
): UseQueryResult<
  TFullResponse extends true ? AxiosResponse<TData> : TData
> => {
  const paramsWithDefaults = { ...defaultParams, ...params }

  return useQuery(
    functionKeys.all(paramsWithDefaults),
    () => getFunctions<TFullResponse>(paramsWithDefaults),
    // @ts-ignore
    options
  )
}
