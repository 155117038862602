/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Configuration, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { deDE } from "@clerk/localizations";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/nextjs"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { IconProvider } from "@synopsisapp/symbiosis-ui"
import { AxiosError } from "axios"
import * as _ from "lodash-es"
import { AppProps } from "next/app"
import posthog from "posthog-js"
import React, { ReactNode, useCallback, useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { I18nextProvider } from "react-i18next"
import { QueryClient, QueryClientProvider, useIsFetching } from "react-query"
// @ts-ignore
import { ReactQueryDevtools } from "react-query/devtools"

import { ConfirmDialogProvider } from "@/components/elements/ConfirmContext/ConfirmProvider"
import { TopBanner } from "@/components/elements/TopBanner"
import Wrapper from "@/components/layouts/Wrapper"
import WithMainNavigationLayout from "components/layouts/WithMainNavigation"
import { AppContextProvider } from "context/AppContext"
import { CurrentUserProvider } from "context/CurrentUserContext"
import { DownloadProvider } from "context/DownloadContext"
import { SidebarProvider } from "context/SidebarContext"
import { TSystemInfo, CURRENT_USER_KEY } from "hooks/data"
import useCookie from "hooks/useCookie"
import { LOCAL_STORAGE_KEYS, EUserRoles, COOKIE_KEYS } from "src/constants"
import { IUser } from "types"
import { fetchJson } from "utils/network"
// eslint-disable-next-line import/order
import { determineAccess } from "utils/determineAccess"

import i18n from "../translations/i18n"

import "public/symbiosis-assets/symbiosis-ui.css"

import "../styles/globals.scss"

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const msalConfig: Configuration = {
  auth: {
    clientId: "",
    authority: "",
    redirectUri: "/",
    postLogoutRedirectUri: "/public/login",
  },
}

const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST

if (!posthogKey || !posthogHost) {
  console.warn(
    "Posthog key or host is not set. Please set the NEXT_PUBLIC_POSTHOG_KEY and NEXT_PUBLIC_POSTHOG_HOST environment variables."
  )
}

if (typeof window !== "undefined" && !!posthogKey) {
  posthog.init(posthogKey, {
    api_host: posthogHost || "https://eu.i.posthog.com",
    ui_host: "https://eu.posthog.com",
    person_profiles: "identified_only",
  })
}

const handle401 = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_EMPLOYEES)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_USERS)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.SKRIBA_AUTH_TOKEN)

  if (typeof window !== "undefined") {
    const { origin, pathname } = window.location
    const newPath = pathname === "/" ? "/certificates" : pathname

    // transfer the query params to the login page
    sessionStorage.setItem("redirectPathAfterLogin", newPath)

    const loginUrl = new URL("/public/login", origin)

    window.location.href = loginUrl.href
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: twentyFourHoursInMs,
      retry(failureCount, error) {
        const { response } = error as AxiosError
        if (response?.status === 403 && response?.statusText === "Forbidden") {
          return false
        }
        return failureCount < 3
      },
      onError(error) {
        if ((error as AxiosError).response?.status === 401) {
          handle401()
        }
      },
    },
  },
})

function LoadingIndicator() {
  const queriesInProgress = useIsFetching()

  useEffect(() => {
    if (queriesInProgress > 0) {
      document.body.style.cursor = "progress"
    } else {
      document.body.style.cursor = "auto"
    }
  }, [queriesInProgress])

  return null
}

function MyApp(props: AppProps): ReactNode {
  const {
    Component,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pageProps,
    router,
  } = props

  const { route } = router
  const isPublicPage = route.startsWith("/public")

  const [isUser, setIsUser] = useState<boolean | undefined>(undefined)
  const [isTechAdmin, setIsTechAdmin] = useState<boolean | undefined>(undefined)
  const [isHr, setIsHr] = useState<boolean | undefined>(undefined)
  const [isSuperHr, setIsSuperHr] = useState<boolean | undefined>(undefined)
  const [authorized, setAuthorized] = useState(false)
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>(
    new PublicClientApplication(msalConfig)
  )
  const allRolesAreSet =
    isUser !== undefined &&
    isHr !== undefined &&
    isSuperHr !== undefined &&
    isTechAdmin !== undefined

  const [systemInfo, setSystemInfo] = useState<TSystemInfo>()

  const { cookieValue: clerkEnabled } = useCookie(
    COOKIE_KEYS.SKRIBA_LOGIN_WITH_CLERK
  )

  useEffect(() => {
    console.log("🔥 x10")
    console.log(!!posthogHost && !!posthogKey ? "✅ 🦔 " : "❌ 🦔")
  }, [])

  useEffect(() => {
    const handleRouteChange = () => posthog?.capture("$pageview")
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router])

  useEffect(() => {
    if (
      clerkEnabled === "true" &&
      localStorage.getItem(LOCAL_STORAGE_KEYS.SKRIBA_AUTH_TOKEN)
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SKRIBA_AUTH_TOKEN)
      void router.replace("/public/login")
    }
  }, [clerkEnabled, router])

  const fetchAuthInfo = useCallback(async () => {
    const { SysRoles } = await queryClient.fetchQuery(
      CURRENT_USER_KEY,
      () =>
        fetchJson<IUser>("benutzer/current", {
          queryParams: {
            includeAuthorizationInfo: false,
          },
        }),
      { retry: false }
    )

    const roleIds = SysRoles.map(({ ID }) => ID)

    return {
      isAuthedUser: roleIds.length === 1,
      isAuthedHr: roleIds.length === 2 && roleIds.includes(EUserRoles.HR),
      isAuthedSuperHr: roleIds.length === 4,
      isAuthedTechAdmin:
        roleIds.length === 2 && roleIds.includes(EUserRoles.TECH_ADMIN),
    }
  }, [])

  useEffect(() => {
    const handleRedirect = async () => {

      let isLocalHr = isHr ?? false;
      let isLocalSuperHr = isSuperHr ?? false;
      let isLocalTechAdmin = isTechAdmin ?? false;
      let isLocalUser = isUser ?? false;

      const url = new URL(window.location.href)

      if (!allRolesAreSet) {
        try {
          const { isAuthedHr, isAuthedSuperHr, isAuthedTechAdmin, isAuthedUser } = await fetchAuthInfo()
          isLocalHr = isAuthedHr;
          isLocalSuperHr = isAuthedSuperHr;
          isLocalTechAdmin = isAuthedTechAdmin;
          isLocalUser = isAuthedUser;
        } catch (error) {
          // We don't care about the error here, it's expected
          // when the user is not authenticated
        }
      }

      const { authorized: shouldAuthorize, redirectTo } = determineAccess({
        isHr: isLocalHr,
        isSuperHr: isLocalSuperHr,
        isTechAdmin: isLocalTechAdmin,
        isUser: isLocalUser,
        route,
      })
      setAuthorized(shouldAuthorize)
      if (redirectTo) {
        url.pathname = redirectTo
        void router.replace(url)
      }
    }

    void handleRedirect()
  }, [
    router,
    route,
    isPublicPage,
    allRolesAreSet,
    clerkEnabled,
    fetchAuthInfo,
    isHr,
    isSuperHr,
    isTechAdmin,
    isUser,
  ])

  useEffect(() => {
    if (isPublicPage) {
      // @ts-ignore
      void fetchJson<TSystemInfo>("system").then((data) => {
        const { AzureAdAuthority, AzureAdClientId } = data

        setMsalInstance(
          new PublicClientApplication({
            ...msalConfig,
            auth: {
              ...msalConfig.auth,
              clientId: AzureAdClientId,
              authority: AzureAdAuthority,
            },
          })
        )
        setSystemInfo(data)
      })
    }

    if (!isPublicPage) {
      const fetchCurrentUserRoles = async () => {
        try {
          const { isAuthedHr, isAuthedSuperHr, isAuthedTechAdmin, isAuthedUser } = await fetchAuthInfo()
          setIsHr(isAuthedHr)
          setIsSuperHr(isAuthedSuperHr)
          setIsTechAdmin(isAuthedTechAdmin)
          setIsUser(isAuthedUser)
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 401) {
            handle401()
          }
        }
      }

      void fetchCurrentUserRoles()
    }
  }, [isPublicPage, fetchAuthInfo])

  return (
    <I18nextProvider i18n={i18n}>
      <ClerkProvider
        {...pageProps}
        localization={{
          ...deDE,
          dividerText: i18n.t("login:orContinueWith"),
          formFieldLabel__emailAddress: i18n.t("login:email"),
          formFieldLabel__password: i18n.t("login:password"),
          formFieldLabel__forgotPassword: i18n.t("login:forgotPassword"),
          formFieldAction__forgotPassword: i18n.t("login:forgotPassword"),
          socialButtonsBlockButton: `${i18n.t("login:signInWith")} {{provider|titleize}}`,
          socialButtonsBlockButtonManyInView: `${i18n.t("login:signInWith")} {{provider|titleize}}`,
        }}
        appearance={{
          layout: {
            socialButtonsVariant: 'blockButton'
          }
        }}
      >
        <QueryClientProvider client={queryClient}>
          <IconProvider>
            <ConfirmDialogProvider>
              <AppContextProvider>
                <LoadingIndicator />

                <MsalProvider instance={msalInstance}>
                  <GoogleOAuthProvider clientId="975346567909-38afadpud1d3f5khv1pjd41d7gvtqgsj.apps.googleusercontent.com">
                    {isPublicPage && (
                      <Component
                        {...{
                          systemInfo,
                          ...pageProps,
                        }}
                      />
                    )}

                    {clerkEnabled !== undefined && (
                      <>
                        {/* Legacy authed */}
                        {!isPublicPage &&
                          clerkEnabled === "false" &&
                          authorized && (
                            <CurrentUserProvider>
                              <TopBanner />
                              <DownloadProvider>
                                <SidebarProvider>
                                  <WithMainNavigationLayout>
                                    <Wrapper>
                                      <Component {...pageProps} />
                                    </Wrapper>
                                  </WithMainNavigationLayout>
                                </SidebarProvider>
                              </DownloadProvider>
                            </CurrentUserProvider>
                          )}

                        {/* Clerk authed */}
                        {clerkEnabled === "true" && (
                          <>
                            <SignedIn>
                              {!isPublicPage && (
                                <CurrentUserProvider>
                                  <TopBanner />
                                  <DownloadProvider>
                                    <SidebarProvider>
                                      <WithMainNavigationLayout>
                                        <Wrapper>
                                          <Component {...pageProps} />
                                        </Wrapper>
                                      </WithMainNavigationLayout>
                                    </SidebarProvider>
                                  </DownloadProvider>
                                </CurrentUserProvider>
                              )}
                            </SignedIn>
                            <SignedOut>
                              {!isPublicPage && <RedirectToSignIn />}
                            </SignedOut>
                          </>
                        )}
                      </>
                    )}

                    <ReactQueryDevtools initialIsOpen={false} />
                  </GoogleOAuthProvider>
                </MsalProvider>

                <Toaster
                  position="top-center"
                  reverseOrder={false}
                  gutter={6}
                  containerClassName=""
                  toastOptions={{
                    className: "",
                    success: {
                      duration: 1500,
                      className: "zzz",
                      iconTheme: {
                        // green-400 (from config)
                        primary: "hsl(112 64% 52%)",
                        secondary: "white",
                      },
                    },
                    error: {
                      duration: 2000,
                      iconTheme: {
                        // red-400 (default)
                        primary: "#f87171",
                        secondary: "white",
                      },
                    },
                    loading: {
                      iconTheme: {
                        // inner spinning element, blue-600 (from config)
                        primary: "hsl(220 99% 54%)",
                        // ring, blue-200 (from config)
                        secondary: "hsl(213 97% 87%)",
                      },
                    },
                  }}
                />
              </AppContextProvider>
            </ConfirmDialogProvider>
          </IconProvider>
        </QueryClientProvider>
      </ClerkProvider>
    </I18nextProvider>
  )
}

export default MyApp
