/* eslint-disable @typescript-eslint/no-redeclare */
import { useQuery, UseQueryOptions } from "react-query"
import { z } from "zod"

import {
  TIntegrationType,
  IntegrationTypeSchema,
} from "@/components/pages/admin/integrations/types"
import { fetchJson } from "utils/network"

const PersonioFieldsSchema = z.object({
  availableFields: z
    .object({
      key: z.string(),
      label: z.string(),
    })
    .array(),
  type: z.literal(IntegrationTypeSchema.Enum.PERSONIO),
})
const IkeaFieldsSchema = z.object({
  availableFields: z
    .object({
      key: z.string(),
      label: z.string(),
    })
    .array(),
  type: z.literal(IntegrationTypeSchema.Enum.IKEA),
})

const AvailableFieldsForIntegrationType = z.union([
  PersonioFieldsSchema,
  IkeaFieldsSchema
])

export type AvailableFieldsForIntegrationType = z.infer<typeof AvailableFieldsForIntegrationType>

const getAvailableFields = async (
  integrationType: TIntegrationType
) => {
  const response = await fetchJson<AvailableFieldsForIntegrationType>(
    `api/ink-integrations/available-fields/${integrationType}`,
    {
      useLocalAir: true,
    }
  )

  const parsedResponse = AvailableFieldsForIntegrationType.parse(response)

  return parsedResponse
}

export function useAvailableFieldsForIntegrationType(
  params: { integrationType: TIntegrationType },
  options?: UseQueryOptions<AvailableFieldsForIntegrationType, Error>
) {
  return useQuery({
    queryKey: ["availableFields", params.integrationType],
    queryFn: () => getAvailableFields(params.integrationType),
    staleTime: 60 * 60 * 1000,
    retry: 0,
    ...options,
    enabled: Boolean(params.integrationType),
  })
}
