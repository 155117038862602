import { Listbox } from "@headlessui/react"
import { XIcon } from "lucide-react"
import { useTranslation } from "react-i18next"

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip"
import {
  Checkmark as CheckmarkIcon,
  ChevronDown as ChevronDownIcon,
} from "assets/icons"
import cn from "utils/classMerge"

import FloatPlacement from "./FloatPlacement"
import Spinner from "../elements/LoadingSpinner"
import Pill from "../elements/Pill/Pill"
import { ErrorMessage } from "../ui/form"

export interface SelectOption<T> {
  value: T
  primary: string | null
  label?: string | null
}

interface Props<T> {
  options: SelectOption<T>[]
  onChange: (value: SelectOption<T>) => void
  label?: string | null
  disabled?: boolean
  value?: SelectOption<T>
  defaultValue?: SelectOption<T>
  onBlur?: () => void
  isLoading?: boolean
  placeholder?: string | null
  error?: string | null
  hasReset?: boolean
  displayLabelPill?: boolean
  className?: string
}

// TODO - rename to Select when old select is removed
export const SelectGeneric = <T,>({
  options,
  onChange,
  onBlur,
  label,
  disabled,
  value,
  defaultValue: defaultValueProp,
  isLoading,
  placeholder,
  error,
  hasReset,
  displayLabelPill,
  className,
}: Props<T>) => {
  const { t } = useTranslation("selectComponent")
  const { t: tShared } = useTranslation("shared")

  const defaultValue = defaultValueProp ?? {
    value: -1 as T,
    primary: placeholder ?? t("placeholder"),
  }

  const getButtonText = () => {
    if (isLoading) {
      return tShared("loading")
    }
    if (value?.value) {
      return value.primary
    }
    return defaultValue.primary
  }

  const showTooltip = (option: SelectOption<T>) => {
    if (option.label && (option.label.length + (option.primary?.length ?? 0)) >= 43) {
      return true
    }
    return false
  }

  return (
    <Listbox
      as={"div"}
      disabled={disabled || isLoading}
      value={value ?? defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
      className={className}
    >
      {label && (
        <Listbox.Label className="mb-2 block text-sm font-semibold text-gray-900">
          {label}
        </Listbox.Label>
      )}

      <FloatPlacement>
        <Listbox.Button
          className={cn(
            "relative w-full cursor-default rounded-md",
            "h-10 pl-3 pr-10 text-left text-base font-light",
            "bg-white text-gray-900 shadow-sm",
            "ring-1 ring-inset ring-black",
            "focus:outline-none focus:ring-2 focus:ring-ocean",
            "sm:text-sm sm:leading-6",
            disabled && "cursor-not-allowed bg-gray-200 opacity-50",
            !value?.value && defaultValue.value === -1 && "text-gray-500"
          )}
        >
          <div className="flex items-center gap-1">
            <span
              className="block truncate text-base"
              dangerouslySetInnerHTML={{ __html: getButtonText() ?? "" }}
            />
            {Boolean(displayLabelPill && value?.label) && (
              <span>
                <Pill type="labelGray" className="truncate">{value?.label}</Pill>
              </span>
            )}
          </div>
          {value && hasReset && (
            <button
              className="absolute inset-y-0 right-10 flex items-center hover:opacity-50"
              onClick={(e) => {
                e.stopPropagation()
                onChange(defaultValue)
              }}
            >
              <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {isLoading ? (
              <Spinner className="h-5 w-5" />
            ) : (
              <ChevronDownIcon
                className="h-4 w-4 text-gray-700"
                aria-hidden="true"
              />
            )}
          </span>
        </Listbox.Button>
        <Listbox.Options className={cn(
          "z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg",
          "ring-1 ring-black focus:outline-none"
          )}>
          {options.map((option, index) => (
            <Listbox.Option
              key={index}
              className={({ active }) =>
                cn(
                  active && "bg-clearSky",
                  "relative cursor-default select-none py-2 pl-4 pr-9"
                )
              }
              value={option}
            >
              {({ selected }) => (
                <>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className= {cn(
                        option.label && 'truncate w-[-webkit-fill-available]'
                      )} asChild>
                        <div className="flex items-center gap-1 text-left">
                          <span
                            className={cn(
                              selected ? "font-semibold" : "font-normal",
                              "block text-base"
                            )}
                          >
                            {option.primary}
                          </span>
                          {option.label && (
                            <Pill type="labelGray"> {option.label}</Pill>
                          )}
                          {selected ? (
                            <span
                              className={cn(
                                "text-black ",
                                "absolute inset-y-0 right-0 flex items-center pr-3"
                              )}
                            >
                              <CheckmarkIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                      </TooltipTrigger>
                      {showTooltip(option) && (
                        <div>
                          <TooltipContent side={index === 0 ? "bottom" : "top"}>
                            {option.label}
                          </TooltipContent>
                        </div>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </FloatPlacement>
      {error && <ErrorMessage error={error} />}
    </Listbox>
  )
}

export default SelectGeneric
