import { z } from "zod"

const BaseField = z
  .object({
    value: z.string(),
    isCustom: z.boolean().optional(),
    isManual: z.boolean().optional(),
    isHours: z.boolean().optional(),
  })
  .strict()

const ManualField = z
  .object({
    value: z.string(),
    isCustom: z.boolean().optional(),
    isManual: z.literal(true),
    isHours: z.boolean().optional(),
    manualMapping: z
      .array(
        z
          .object({
            id: z.string(),
            skribaOption: z.string(),
            remoteOptions: z.array(z.string()),
          })
          .strict()
      )
      .optional()
      .default([]),
  })
  .strict()
export type TManualField = z.infer<typeof ManualField>

const HoursField = z
  .object({
    value: z.string(),
    isCustom: z.boolean().optional(),
    isManual: z.boolean().optional(),
    isHours: z.literal(true),
    baseHours: z.number(),
    hoursMapping: z
      .array(
        z
          .object({
            id: z.string(),
            baseHours: z.number(),
            remoteDepartments: z.array(z.string()),
            remoteFunctions: z.array(z.string()),
          })
          .strict()
      )
      .optional()
      .default([]),
  })
  .strict()
export type THoursField = z.infer<typeof HoursField>

const BaseOrManualField = z.union([BaseField, ManualField])
const BaseOrHoursField = z.union([BaseField, HoursField])

export const MappingSchema = z.object({
  PersonnelNumber: BaseField,
  Birthdate: BaseField,
  FirstName: BaseField,
  LastName: BaseField,
  Gender: BaseOrManualField,
  Hometown: BaseField.optional(),
  ExitDate: BaseField.optional(),
  Employment_FromDate: BaseField,
  Employment_LevelOfEmployment: BaseOrHoursField,
  Employment_DepartmentCode: BaseOrManualField,
  Employment_FunctionCode: BaseOrManualField,
  Employment_EmploymentType: BaseOrManualField.optional(),
  Employment_NumberOfEmployees: BaseField.optional(),
})

export type TMapping = z.infer<typeof MappingSchema>
