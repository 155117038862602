/* eslint-disable import/order */
import * as React from 'react';
import Modal from '../Modal';
import { useDisclose } from 'hooks/useDisclose';

export type ConfirmDialogContextProps = {
  title: string;
  description: React.ReactNode;
  confirmText: string;
  cancelText: string;
  confirmTone?: 'default' | 'destructive';
  swapButtons?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

type ConfirmDialogContextValue = {
  openConfirmDialog: (options: Omit<ConfirmDialogContextProps, 'onConfirm' | 'onCancel'>) => Promise<void>;
}

export const ConfirmDialogContext = React.createContext<ConfirmDialogContextValue | undefined>(undefined);

export const ConfirmDialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [confirmOptions, setConfirmOptions] = React.useState<ConfirmDialogContextProps | null>(null);
  const { onOpen, onClose, isOpen } = useDisclose();

  React.useEffect(() => {
    if (!confirmOptions?.title) return;

    onOpen();
  }, [confirmOptions?.title, onOpen])

  const closeConfirmDialog = () => {
    setConfirmOptions(null);
  };

  const openConfirmDialog = (options: Omit<ConfirmDialogContextProps, 'onConfirm' | 'onCancel'>) => {
    return new Promise<void>((resolve, reject) => {
      setConfirmOptions({
        ...options,
        onConfirm: resolve,
        onCancel: reject,
      });
    }).finally(closeConfirmDialog);
  };


  return (
    <ConfirmDialogContext.Provider value={{ openConfirmDialog }}>
      {children}
      {
        confirmOptions && (
          <Modal
            title={confirmOptions.title}
            subtitle={confirmOptions.description}
            show={isOpen}
            cancelButtonText={confirmOptions.cancelText}
            onClose={() => {
              confirmOptions.swapButtons ? confirmOptions.onConfirm() : confirmOptions.onCancel();
              onClose();
            }}
            confirmButtonText={confirmOptions.confirmText}
            onConfirmButtonClick={() => {
              confirmOptions.swapButtons ? confirmOptions.onCancel() : confirmOptions.onConfirm();
              onClose();
            }}
          />
        )
      }
    </ConfirmDialogContext.Provider>
  );
};
