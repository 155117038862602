import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import useCookie from "hooks/useCookie"
import { COOKIE_KEYS } from "src/constants"
import { IUser } from "types"
import { fetchJson } from "utils/network"

type TData = unknown
type TError = unknown
type TVariable = IUser
type THook = (
  options?: UseMutationOptions<TData, TError, TVariable>
) => UseMutationResult<TData, TError, TVariable>

const deleteUser = ({ user, clerkEnabled }: { user: TVariable, clerkEnabled?: boolean }) => {
  if (clerkEnabled) {
    return fetchJson("external/delete-user", {
      method: "DELETE",
      body: { email: user.Email },
      useLocalAir: true,
      queryParams: {
        domain: window.location.host
      }
    })
  } else {
    // @ts-ignore
    return fetchJson<TData>(`Benutzer/${user.BenutzerID}`, { method: "DELETE" })
  }
}

export const useDeleteUser: THook = (options) => {
  const { t } = useTranslation("toastComponent")
  const { cookieValue: clerkLoginCookieValue } = useCookie(
    COOKIE_KEYS.SKRIBA_LOGIN_WITH_CLERK
  )

  const clerkEnabled = clerkLoginCookieValue === "true"
  let toastId: ReturnType<typeof toast.loading>

  return useMutation((variables) => {
    return deleteUser({
      user: variables,
      clerkEnabled,
    })
  }, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
