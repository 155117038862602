import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import { z } from "zod"

import { IntegrationTypeSchema } from "@/components/pages/admin/integrations/types"
import { fetchJson } from "utils/network"

import { MappingSchema } from "./types"

const AvailableIntegrationsSchema = z.object({
  type: IntegrationTypeSchema,
})

type RemoteUniqueValues = {
  remoteDepartments: string[];
  remoteFunctions: string[];
  remoteGenders: string[];
  remoteEmploymentTypes: string[];
};

const GetIntegrationsSchema = z.object({
  activeIntegrations: AvailableIntegrationsSchema.extend({
    mappings: MappingSchema.optional(),
    remoteUniqueValues: z.custom<RemoteUniqueValues>().optional(),
  }).array(),
  availableIntegrations: AvailableIntegrationsSchema.array(),
})

type TGetIntegrations = z.infer<typeof GetIntegrationsSchema>

type THook = (
  options?: UseQueryOptions<TGetIntegrations>
) => UseQueryResult<TGetIntegrations>

const getIntegrations = async () => {
  const maybeIntegrations = await fetchJson<TGetIntegrations>(
    `api/ink-integrations`,
    {
      useLocalAir: true,
    }
  )

  const integrations = GetIntegrationsSchema.parse(maybeIntegrations)

  return integrations
}

export const useIntegrations: THook = (options) =>
  useQuery({
    queryKey: ["integrations"],
    queryFn: () => getIntegrations(),
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000, // 1 hour
    retry: 0,
    ...options,
  })
