import { AxiosError } from "axios"
import * as _ from "lodash-es"
import { useRouter } from "next/router"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import * as TsToolbelt from "ts-toolbelt"

import {
  EEvaluationCategoryCodes,
  IEvaluationCategory,
  TClosingStatementGroup,
  TClosingStatementInGroup,
  TLanguageNames,
} from "hooks/data"
import { EOrganizationTypes } from "src/constants"
import { IItem, TLanguageCodes, TDateFormat } from "types"
import { fetchJson } from "utils/network"

import { certificateKeys } from "./keys"
import { EClosingStatementGroups } from "../closingStatements"

export interface ITask {
  AufgabeID: number
  Bezeichnung: string
  CatAufgabeTemplate: IItem | null
  Sequenz?: number
  ZeugnisAufgabeSequenz?: number
  BeschaeftigungID?: number
  IsDeactivated?: boolean
}

// Alina Arnold

export enum ECertificateStatuses {
  IN_PROGRESS = "in_progress",
  BLOCKED = "blocked",
  DONE = "done",
  REQUEST = "request"
}

type TUserFunction = {
  CatFunktionID: number | null
  CatFunktionName: string | null
}

export type TCustomTaskGroupTask = TsToolbelt.Object.Writable<
  {
    CustomTaskID: number
    Title: string
    Sequenz: number
    UseInCertificate: boolean
  },
  "Sequenz"
>
export type TCustomTaskGroup = {
  CustomTaskGroupID: number
  Title: string
  Sequenz: number
  Tasks: Array<TCustomTaskGroupTask>
}

export type UserShort = TUserFunction & IItem

export interface ICertificate {
  AktuellerZeugnisBearbeiter: {
    Termin: TDateFormat | null
    Benutzer: TUserFunction & IItem
    ZeugnisBearbeiterID: number
    AssignEmailDate: TDateFormat | null
    AssignToNotes: string | null
    ReturnToUser: UserShort | null
    AllowManualEditConclusion?: boolean | null
    AllowManualEditEducation?: boolean | null
    AllowManualEditEvaluation?: boolean | null
    AllowManualEditIntroduction?: boolean | null
  } | null
  Aufgaben: ITask[]

  // Task introduction
  // Task section / intro sentence for Job
  AufgabenSectionText: {
    IntroText: string
    Tasks: {
      TaskId: number
      JobId: number
      Text: string
    }[]
  }[]

  // Introduction - job history
  BeschaeftigungsText: string
  BeurteilterMitarbeiter: IItem
  Beurteilungen: {
    CatBeurteilungsOptionID: number
    UseCustomTemplate: boolean
    CustomTemplate: string
  }[]
  BeurteilungsOptionen: IEvaluationCategory[]
  BeurteilungsStatistik: {
    Code: EEvaluationCategoryCodes
    AnzahlAussagenSoll: number
    AnzahlAussagenIst: number
  }[]
  BeurteilungsTexte: {
    CatBereichID: number
    Code: string
    BeurteilungsTexte: {
      CatKriteriumID: number
      CatBeurteilungLevelID: number
      CatBeurteilungsOptionID: number
      Text: string
    }[]
  }[]
  CatBeurteilungsLevel: {
    ID: number
  } | null
  CatBearbeitungsStatus: {
    ID: number
    Code: ECertificateStatuses
    DisplayName: string
  }
  CatSprache: {
    ID: TLanguageCodes
    DisplayName: TLanguageNames
  }
  CatZeugnisTyp: IItem
  CatStandort: IItem
  CompanyAlternativeNameID: number | null
  CompanyAlternativeNameTexte: string | null
  CompletedByBenutzer: IItem
  CompletedByDate: string | null

  // Introduction - (Name, DOB, citizenship, etc.)
  EinleitungsText: string
  IntroTemplateTexte: string
  Funktion1: string | null
  Funktion2: string | null
  HideEducationSection: boolean
  ModDate: TDateFormat
  Note: string | null
  OrganisationsEinheitTyp: EOrganizationTypes
  OrtUndZeitText: string
  Schlussformeln: {
    CatSchlussformelOptionID: number
    Werte: []
  }[]
  SchlussformelOptionen: Array<
    Omit<
      TClosingStatementGroup,
      "CatSchlussformelBlockID" | "SchlussformelOptionen"
    > & {
      SchlussformelOptionBlockID: EClosingStatementGroups
      SchlussformelOptionen: Array<
        TClosingStatementInGroup & {
          Text: string
        }
      >
    }
  >
  SchlussformelTexte: {
    SchlussformelTexte: {
      Text: string
    }[]
  }[]
  UnterschriftBerechtigungsText: string
  VerantwortlicherHRBenutzer: UserShort
  ZeugnisBeschaeftigung: {
    BeschaeftigungID: number
    IsActive: boolean
    OrganisationsEinheitTyp: EOrganizationTypes | null
    CatIntroductionOptionID: number | null
    OrganisationsEinheitTypRendered?: string | null
  }[]
  Zeugnisdatum: TDateFormat | null
  // History of assignments + comments.
  ZeugnisBearbeiter: {
    ZeugnisBearbeiterID: number
    // Deadline for handing it back.
    Termin: TDateFormat | null
    // When to send the assignment notification email.
    AssignEmailDate?: TDateFormat | null
    // Comment to include in the assigment email.
    AssignToNotes?: string | null
    // Assigner.
    AssignedBy: {
      ID: number
      DisplayName: string
      CatFunktionID: number
      CatFunktionName: string
    } | null
    // Assignee.
    Benutzer: {
      ID: number
      DisplayName: string
      CatFunktionID: number
      CatFunktionName: string
    }
    // Assignment date.
    CreaDate: TDateFormat
  }[]
  ZeugnisID: number
  Zwischenzeugnisdatum: TDateFormat | null
  UseSectionInFunctions: boolean

  // Signatures
  UseCustomizedSignature: boolean
  CustomizedSignatureA: string | null
  CustomizedSignatureB: string | null
  CustomizedSignatureAPosition: string | null
  CustomizedSignatureBPosition: string | null
  SignatureABenutzerID: number | null
  SignatureBBenutzerID: number | null
  Unterschrift1: string | null
  Unterschrift2: string | null

  // Custom section: intro
  IntroCustomizedTemplate: string | null
  // Stoer this with customized text for introduction
  IntroCustomizedTexte: string | null
  IntroTemplate: string

  // Change this to to toggle IntroCustomizedTemplate
  UseIntroCustomizedTemplate: boolean

  // Custom section: evaluation
  EvaluationCustomizedTemplate: string
  EvaluationCustomizedTexte: string
  EvaluationTemplate: string
  UseEvaluationCustomizedTemplate: boolean

  // Custom section: conclusion
  ConclusionCustomizedTemplate: string
  ConclusionCustomizedTexte: string
  ConclusionTemplate: string
  UseConclusionCustomizedTemplate: boolean

  // Custom section: tasks
  AusbildungsText: string
  AusbildungsTemplate: string
  AusbildungsCustomizedTemplate: string
  AusbildungsCustomizedTexte: string
  UseAusbildungsCustomizedTemplate: boolean

  // Template settings
  LocationAndDatePosition: number
  CompanySloganPosition: number
  FirmaSubtitle?: string

  PrintCompanyNameInSignature: boolean
  VornameUndNameFett: boolean

  // Custom task blocks
  UseCustomTasks: boolean
  CustomTaskGroups: Array<TCustomTaskGroup>
  LocalHRBenutzer: UserShort | null
  LineManagerBenutzer: UserShort | null
}

const getCertificate = (id?: number) =>
  id
    ? // @ts-ignore
      fetchJson<ICertificate>(`Zeugnisse/${id}`)
    : Promise.reject(new Error("Missing id"))

type TData = ICertificate
type THook = (
  id?: number,
  options?: UseQueryOptions<TData>
) => UseQueryResult<TData>

export const useCertificate: THook = (id, options) => {
  const router = useRouter()
  const { t } = useTranslation("toastComponent")

  // @ts-ignore
  return useQuery(certificateKeys.one(id), () => getCertificate(id), {
    onError(...args) {
      const error = args[0] as AxiosError<{ Message?: string }>

      if (
        error.response?.status === 403 &&
        error.response?.statusText === "Forbidden"
      ) {
        toast.error(t("errors.accessDenied"))
        void router.push("/certificates")
      }
      if (
        error.response?.data.Message ===
        "GET_DISALLOWED_FOR_WORKFLOW_STATE_DONE"
      ) {
        toast.error(t("errors.cannotViewDoneCertificate"))
        void router.push("/certificates")
      }

      options?.onError?.(...args)
    },
    ..._.omit(options, "onError"),
    retry(failureCount, error) {
      const { response } = error as AxiosError<{ Message?: string }>
      if (response?.data.Message === "GET_DISALLOWED_FOR_WORKFLOW_STATE_DONE") {
        return false
      }
      if (response?.status === 404) return false
      return failureCount < 3
    },
    enabled: Boolean(id),
    refetchOnWindowFocus(query) {
      const error = query.state.error as AxiosError
      return error?.response?.status !== 404
    },
  })
}
